import React from "react"
import EventDetails from "../../components/EventsPage"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const event = {
  title: "Create Your Creativity",
  category: "Lomba",
  partner: "Rifqi Art Studio",
  date: "29 Maret 2022 - 5 Mei 2022",
  slug: "create-your-creativity",
  price: "Gratis",
  thumb:
    "https://i.postimg.cc/nVYQCQXK/POSTER-LOMBA-RIFQIARTSTUDIO-2022-01.jpg",
  description: {
    __html: `<p><img style="max-width: 100%;" src="https://i.postimg.cc/nVYQCQXK/POSTER-LOMBA-RIFQIARTSTUDIO-2022-01.jpg" alt="Poster dari Lomba Create Your Creativity" /></p>`,
  },
  karyaPemenang: true,
  url: "https://linktr.ee/purwalenta",
}

const EventPage = () => {
  return (
    <Layout>
      <SEO title={event.title} />
      <EventDetails event={event} />
    </Layout>
  )
}

export default EventPage

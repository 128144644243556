import React from "react"
import EventStyles from "./style"
import Button from "../Reusable/Button"
import { AiFillFacebook } from "@react-icons/all-files/ai/AiFillFacebook"
import { AiFillLinkedin } from "@react-icons/all-files/ai/AiFillLinkedin"
import { FiTwitter } from "@react-icons/all-files/fi/FiTwitter"
import { toast } from "react-toastify"

const EventDetails = ({ event }) => {
  const share = async () => {
    if (navigator.share) {
      try {
        const shareDetails = {
          url: `https://purwalenta.com/events/${event.slug}`,
          title: event.title,
          text: `Yuk lihat event ${event.title} hanya di Purwalenta!`,
        }
        await navigator.share(shareDetails)
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`)
        copyUrl()
      }
    } else {
      copyUrl()
    }
  }

  const copyUrl = () => {
    navigator &&
      navigator.clipboard.writeText(
        `https://purwalenta.com/events/${event.slug}`
      )
    toast.success("Link copied to clipboard!")
  }

  return (
    <EventStyles>
      <div
        className="bg-head"
        style={{
          background: `yellow url(${event.thumb}) center center no-repeat`,
        }}
      >
        <div className="layer" />
        <div
          className="head"
          style={{
            background: `yellow url(${event.thumb}) center center no-repeat`,
          }}
        >
          <div className="avatar"></div>
        </div>
      </div>
      <div className="main">
        <div className="main-head">
          <h3>{event.title}</h3>
          <div style={{ paddingTop: "0.5rem" }}>
            {event.price && <h4>💵 {event.price}</h4>}
          </div>
          <p>
            <span className="price">
              💵 {event.price}
              <br />
            </span>
            📆 <span style={{ fontWeight: 600 }}></span>
            {event.date}
            <br /> {event.location && <>📌 {event.location}</>}
          </p>
          {event.isOver ? (
            <Button style={{ maxHeight: "3rem" }} disabled>
              Event telah berakhir
            </Button>
          ) : (
            <Button newPage href={event.url} style={{ maxHeight: "3rem" }}>
              {event.karyaPemenang ? "Karya Pemenang" : "Tonton Rekaman"}
            </Button>
          )}
        </div>
        <hr />
        <div dangerouslySetInnerHTML={event.description}></div>
        <br />
        <br />
        <div>
          <h4>Bagikan event ini</h4>
          <div className="social-container">
            <div className="social-button">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=https://purwalenta.com/events/${event.slug}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "unset" }}
              >
                <AiFillFacebook />
              </a>
            </div>
            <div className="social-button">
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=https://purwalenta.com/events/${event.slug}&title=${event.title}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "unset" }}
              >
                <AiFillLinkedin />
              </a>
            </div>
            <div className="social-button">
              <a
                href={`http://twitter.com/share?text=${event.title}&url=https://purwalenta.com/events/${event.slug}&hashtags=purwalenta`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "unset" }}
              >
                <FiTwitter />
              </a>
            </div>
            <div className="social-button salin" onClick={share}>
              Bagikan
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        <div className="bottom-title">
          Webinar Strategi Membangun Bisnis Ala Bos Startup
        </div>
        <Button>Gabung Event</Button>
      </div>
    </EventStyles>
  )
}

export default EventDetails

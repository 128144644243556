import styled from "styled-components"

const EventStyles = styled.div`
  color: #4b5563;
  font-size: 1.12rem;
  background-color: f4f4f4;

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #1f2937;
  }

  .bg-head {
    position: relative;
    display: flex;
    width: 100%;
    height: 200px;

    @media (min-width: 768px) {
      height: 270px;
    }

    .layer {
      display: none;
      position: absolute;
      width: 100vw;
      height: 100%;
      backdrop-filter: blur(16px) brightness(80%);

      @media (min-width: 768px) {
        display: block;
      }
    }

    .head {
      position: relative;
      bottom: 0;
      margin-top: auto;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 768px;
      height: 100%;
      border-radius: 8px 8px 0 0;
      color: white;

      @media (min-width: 768px) {
        height: 180px;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
          0 4px 6px -4px rgb(0 0 0 / 0.1);
      }

      .avatar {
        display: none;
        position: absolute;
        height: 6rem;
        width: 6rem;
        border-radius: 100px;
        bottom: 0;
        left: 0;
        transform: translate(1rem, 2rem);
        z-index: 20;
        border: 5px solid white;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
          0 4px 6px -4px rgb(0 0 0 / 0.1);

        @media (min-width: 768px) {
          transform: translate(2rem, 2rem);
        }
      }
    }
  }

  .main {
    position: relative;
    padding: 1rem;
    padding-top: 3rem;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border-radius: 0 0 8px 8px;
    margin-bottom: 8rem;

    .main-head {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      justify-content: space-between;
      font-size: 1rem;

      h3 {
        max-width: 480px;
      }

      h4 {
        display: none;
        font-weight: 400;
      }

      @media (min-width: 768px) {
        p {
          .price {
            display: none;
          }
        }
      }

      @media (min-width: 768px) {
        grid-template-columns: 1fr auto;

        h4 {
          display: block;
          text-align: right;
        }
      }
    }

    @media (min-width: 768px) {
      padding: 3rem;
      box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
        0 4px 6px -4px rgb(0 0 0 / 0.1);
    }

    .social-container {
      display: flex;
      gap: 0.5rem;
      padding-top: 1rem;
      font-size: 0.875rem;

      .social-button {
        padding: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ecfccb;
        color: #4b5563;
        border-radius: 50px;
        height: 2.5rem;
        min-width: 2.5rem;
        cursor: pointer;

        svg {
          font-size: 1.5rem;
        }
      }

      .social-button:hover {
        background-color: #a3e635;
        color: white;
      }

      .salin {
        padding-left: 1rem;
        padding-right: 1rem;
        font-weight: 500;
      }
    }
  }

  .bottom-bar {
    display: none;
    justify-content: space-between;
    z-index: 25;
    align-items: center;
    position: sticky;
    background-color: white;
    height: 4rem;
    width: 100%;
    bottom: 1rem;
    margin-bottom: 8rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    padding: 1rem 3rem;
    border: 2px solid rgb(0, 0, 0, 0.1);

    @media (min-width: 768px) {
      display: none;
    }

    .bottom-title {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    .button-join {
    }
  }
`

export default EventStyles
